export const dataLabels = {
  "1000Genomes": "1000 Genomes",
  CONGENICA_AI: "Congenica AI",
  ENSEMBL: "Ensembl",
  EXAC_EXOMES: "ExAC",
  EXOMISER: "Exomiser",
  EXOMISER_ACMG_ANNOTATION: "Exomiser ACMG Annotation",
  GNOMAD_EXOMES: "gnomAD",
  GNOMAD_GENE_CONSTRAINT_SCORES: "gnomAD Gene Constraint Scores",
  HPO_DB: "HPO",
  HPO_EXOMISER: "HPO (Exomiser)",
  VARSOME_API_ACMG_ANNOTATION: "Varsome ACMG Annotation",
  VEP_CACHE: "VEP Cache",
};

export const softwareLabels = {
  CONGENICA_AI: "Congenica AI",
  DBSC_SNV: "dbscSNV",
  EXOMISER: "Exomiser",
  EXOMISER_ACMG_ANNOTATION: "Exomiser ACMG Annotation",
  MAST: "MAST",
  MAXENTSCAN: "MaxEntScan",
  SPLICE_AI: "SpliceAI",
  SSF: "SSF",
  VEP: "VEP",
};

export const labels = {
  data: dataLabels,
  software: softwareLabels,
};
