import { identity, memoizeWith, path } from "ramda";
import { PureComponent } from "react";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";

import { Loading, Tabs } from "pattern-library";

import * as authSelectors from "modules/auth/selectors";
import CVLContainer from "modules/curatedVariantsLists/CVLContainer";
import PatientsContainer from "modules/patients/PatientsContainer";
import * as projectSelectors from "modules/project/selectors";
import GenePanels from "modules/projectGenePanels/GenePanels";
import { ProjectIRsContainer } from "modules/projectInterpretationRequests";
import ConnectedProjectSettingsContainer from "modules/projectSettings/ProjectSettingsContainer";
import AddProject from "modules/projectSettings/components/addProject/AddProject";
import UsersContainer from "modules/projectUsers/Container";
import {
  ONCOLOGY_PROJECT_TYPE,
  RARE_DISEASE_PROJECT_TYPE,
} from "modules/projects/constants";

import {
  NotFoundMessage,
  RouteTabContent,
  RouteTabHeader,
} from "../../layout/partials";
import * as authActions from "../auth/actions";

import * as actions from "./actions";
import ProjectsTable from "./components/ProjectsTable";
import * as selectors from "./selectors";

const TABS = (
  projectsUrl,
  projectId,
  counts,
  user,
  projectCurrentUser,
  projectType
) => {
  const { admin: isAdmin } = user;
  const {
    roles = {},
    canAdminProject = false,
    tabs = {},
    sampleUpload = false,
  } = projectCurrentUser || {};
  return {
    INTERPRETATION_REQUESTS: {
      name: "Interpretation requests",
      component: ProjectIRsContainer,
      url: `${projectsUrl}/interpretation-requests`,
      count: path(["interpretationRequests"], counts),
      componentProps: {
        projectId: `${projectId}`,
      },
      isHidden: !(path(["curator"], roles) || canAdminProject || isAdmin),
    },
    PATIENTS: {
      component: PatientsContainer,
      componentProps: {
        projectId: `${projectId}`,
        showSampleUploadButton: sampleUpload,
        writeAccess: path(["curator"], roles) || path(["submitIr"], roles),
      },
      name: "Patients",
      url: `${projectsUrl}/patients`,
      count: path(["patients"], counts),
      stopUnmount: false,
    },
    GENE_PANELS: {
      name: "Gene panels",
      url: `${projectsUrl}/gene-panels`,
      component: GenePanels,
      componentProps: {
        projectId: `${projectId}`,
      },
      count: path(["genePanels"], counts),
      isHidden: !(isAdmin || path(["genePanelCurator"], roles)),
    },
    CURATED_VARIANT_LISTS: {
      // CURATED VARIANT LISTS
      component: CVLContainer,
      componentProps: {
        projectId: parseInt(projectId),
      },
      name: "Curated variant lists",
      url: `${projectsUrl}/curated-variant-lists`,
      count: path(["curatedVariantLists"], counts),
      isHidden: !tabs.cvl,
    },
    USERS: {
      component: UsersContainer,
      componentProps: {
        projectId: `${projectId}`,
      },
      name: "Users",
      url: `${projectsUrl}/users`,
      count: path(["users"], counts),
      isHidden: !canAdminProject,
    },
    SETTINGS: {
      name: "Settings",
      component: ConnectedProjectSettingsContainer,
      componentProps: {
        projectId: `${projectId}`,
        projectCurrentUser,
        user,
        projectType,
      },
      url: `${projectsUrl}/settings`,
      isHidden: !canAdminProject,
    },
  };
};

const getRareDiseaseProjectTabSet = (
  projectsUrl,
  projectId,
  counts,
  user,
  projectCurrentUser
) => {
  const tabs = TABS(
    projectsUrl,
    projectId,
    counts,
    user,
    projectCurrentUser,
    RARE_DISEASE_PROJECT_TYPE
  );

  return [
    tabs.PATIENTS,
    tabs.INTERPRETATION_REQUESTS,
    tabs.GENE_PANELS,
    tabs.CURATED_VARIANT_LISTS,
    tabs.USERS,
    tabs.SETTINGS,
  ];
};

const getOncologyProjectTabSet = (
  projectsUrl,
  projectId,
  counts,
  user,
  projectCurrentUser
) => {
  const tabs = TABS(
    projectsUrl,
    projectId,
    counts,
    user,
    projectCurrentUser,
    ONCOLOGY_PROJECT_TYPE
  );

  return [
    tabs.PATIENTS,
    tabs.INTERPRETATION_REQUESTS,
    tabs.USERS,
    tabs.SETTINGS,
  ];
};

export const getProjectTabSet = (
  projectId,
  counts,
  user,
  projectCurrentUser,
  projectType
) => {
  const projectsUrl = `projects/${projectId}`;

  return memoizeWith(
    identity,
    (projectsUrl, projectId, counts, user, projectCurrentUser) =>
      projectType === ONCOLOGY_PROJECT_TYPE
        ? getOncologyProjectTabSet(
            projectsUrl,
            projectId,
            counts,
            user,
            projectCurrentUser
          )
        : getRareDiseaseProjectTabSet(
            projectsUrl,
            projectId,
            counts,
            user,
            projectCurrentUser
          )
  )(projectsUrl, projectId, counts, user, projectCurrentUser);
};

interface Props {
  legacyProjectId?: string;
  projectType: ReturnType<typeof selectors.getProjectType>;
  loaded: ReturnType<typeof selectors.isLoaded>;
  projects: ReturnType<typeof selectors.getAllProjects>;
  currentProject: ReturnType<typeof projectSelectors.getCurrentProject>;
  projectCurrentUser: ReturnType<typeof projectSelectors.getProjectCurrentUser>;
  counts: ReturnType<typeof projectSelectors.getCurrentProjectTabCounts>;
  user: ReturnType<typeof authSelectors.currentUser>;

  initialise: typeof actions.readProjects;
  getCurrentUser: typeof authActions.isUserLoggedIn;
}

export class ProjectsContainer extends PureComponent<
  Props & RouteComponentProps<{ projectId?: string }>
> {
  componentDidMount() {
    const { getCurrentUser } = this.props;
    getCurrentUser();
    const { initialise } = this.props;
    initialise();
  }

  routeToProject = projectId => {
    const { history } = this.props;
    history.push(`/projects/${projectId}/patients`);
  };

  render() {
    const {
      loaded,
      projects,
      user,
      counts,
      currentProject,
      projectCurrentUser = {},
      projectType,
      // This is only passed when Perl is rendering the projects table on it's own
      // It's required so the component can correctly move to the page with the project on
      legacyProjectId,
      match: { params: { projectId: routerProjectId } = {} } = {},
    } = this.props;

    const projectId = legacyProjectId || routerProjectId;

    if (!loaded) {
      return <Loading />;
    }

    const tabs = getProjectTabSet(
      projectId,
      counts,
      user,
      projectCurrentUser,
      projectType
    );

    const showAddProject = Boolean(
      projectId &&
        !legacyProjectId &&
        currentProject &&
        (user.admin || projectCurrentUser.canAdminProject)
    );

    return (
      <div className="projects-container">
        <ProjectsTable
          projectId={projectId}
          projects={projects}
          title={`Projects for user ${user.name}`}
        />
        {showAddProject && (
          <div className="row">
            <div className="col-md-12">
              <AddProject
                projectId={projectId}
                projectName={currentProject.code}
                onProjectAdded={this.routeToProject}
              />
            </div>
          </div>
        )}
        {/*
          We have to check the legacy project ID here, as we can't render Switch/Route components in the Perl application
        */}
        {projectId && !legacyProjectId && (
          <div className="tabset">
            {currentProject && (
              <h4 className="header-container">
                {currentProject.code}
                <div className="header-description">
                  {" "}
                  <small title={currentProject.description}>
                    {currentProject.description}
                  </small>
                </div>
              </h4>
            )}
            <Switch>
              <Route exact path={`/projects/${projectId}`}>
                <Redirect to={`/projects/${projectId}/patients`} />
              </Route>
              <Tabs
                tabs={tabs}
                routePath="/"
                baseUrl="/"
                tabComponent={RouteTabHeader}
                stopUnmount
                contentComponent={RouteTabContent}
                projectId={projectId}
              />
              <Route component={NotFoundMessage} />
            </Switch>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (
  state,
  ownProps: Props & RouteComponentProps<{ projectId?: string }>
) => {
  const { legacyProjectId, match: { params: { projectId } = {} } = {} } =
    ownProps;
  return {
    projectType: selectors.getProjectType(state, legacyProjectId || projectId),
    loaded: selectors.isLoaded(state),
    projects: selectors.getAllProjects(state),
    currentProject: projectSelectors.getCurrentProject(state),
    projectCurrentUser: projectSelectors.getProjectCurrentUser(state),
    counts: projectSelectors.getCurrentProjectTabCounts(state),
    user: authSelectors.currentUser(state),
  };
};

const mapDispatchToProps = {
  initialise: actions.readProjects,
  getCurrentUser: authActions.isUserLoggedIn,
};

//It is necessary for using in a perl application. Because we have problem with routing in other case.
export const connector = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsContainer);

export default withRouter(connector);
