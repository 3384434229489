import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { LoadingOverlay, Download, Actions } from "pattern-library";

import OncologyVariantTabs from "modules/oncologyExplore/OncologyVariantTabs";
import ReclassificationLegend from "modules/oncologyExplore/components/ReclassificationLegend";
import { API_ENTITIES_BASE_URL } from "modules/utils/baseUrls";

import { VariantDriverClassifications } from "./common-types";
import { ConfirmationModal } from "./components/ConfirmationModal";
import { useOncologyExploreData } from "./useOncologyExploreData";
import {
  getVariantDriverClassificationsDiff,
  getVariantDriverClassifications,
} from "./utils";

const OncologyExploreContainer: FC<PatientDependant & ProjectDependant> = ({
  patientId,
  projectId,
}) => {
  const history = useHistory();

  const [reclassificationInProgress, setReclassificationInProgress] =
    useState(false);
  const [
    completeReclassificationDialogVisible,
    setCompleteReclassificationDialogVisible,
  ] = useState(false);
  const [approveVariantsDialogVisible, setApproveVariantsDialogVisible] =
    useState(false);
  const [rejectVariantsDialogVisible, setRejectVariantsDialogVisible] =
    useState(false);
  const [
    revertReclassificationsDialogVisible,
    setRevertReclassificationsDialogVisible,
  ] = useState(false);

  const [genomeBrowserShown, setGenomeBrowserShown] = useState<boolean>(false);

  const {
    reclassifyVariants,
    revertVariants,
    approveVariants,
    rejectVariants,
    snv,
    cnv,
    rnaFusions,
    oncologyPatientWorkflowStatus,
    reclassificationFileSubtype,
    isLoading,
  } = useOncologyExploreData(patientId);

  const [snvDriverClassifications, setSnvDriverClassifications] =
    useState<VariantDriverClassifications>({});
  const [cnvDriverClassifications, setCnvDriverClassifications] =
    useState<VariantDriverClassifications>({});
  const [fusionsDriverClassifications, setFusionsDriverClassifications] =
    useState<VariantDriverClassifications>({});

  useEffect(() => {
    setSnvDriverClassifications(getVariantDriverClassifications(snv));
  }, [snv]);

  useEffect(() => {
    setCnvDriverClassifications(getVariantDriverClassifications(cnv));
  }, [cnv]);

  useEffect(() => {
    setFusionsDriverClassifications(
      getVariantDriverClassifications(rnaFusions)
    );
  }, [rnaFusions]);

  const snvDriverChanges = useMemo(
    () => getVariantDriverClassificationsDiff(snvDriverClassifications, snv),
    [snv, snvDriverClassifications]
  );
  const cnvDriverChanges = useMemo(
    () => getVariantDriverClassificationsDiff(cnvDriverClassifications, cnv),
    [cnv, cnvDriverClassifications]
  );
  const rnaFusionsDriverChanges = useMemo(
    () =>
      getVariantDriverClassificationsDiff(
        fusionsDriverClassifications,
        rnaFusions
      ),
    [rnaFusions, fusionsDriverClassifications]
  );

  const driverChangesPresent =
    Object.keys(snvDriverChanges).length > 0 ||
    Object.keys(cnvDriverChanges).length > 0 ||
    Object.keys(rnaFusionsDriverChanges).length > 0;

  const handleBeforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (reclassificationInProgress && driverChangesPresent) {
        // Triggers the browser's default alert
        e.preventDefault();
        return false;
      }

      return undefined;
    },
    [reclassificationInProgress, driverChangesPresent]
  );

  /**
   * Handle prompting user that there are unsaved changes when:
   *  closing tab
   *  closing browser
   *  refreshing page
   *  browser back / navigating without react router (currently)
   */
  useEffect(() => {
    // Attach the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  const handleStartReclassificationButtonClick = () => {
    setReclassificationInProgress(true);
  };

  const handleCancelReclassificationButtonClick = useCallback(() => {
    setReclassificationInProgress(false);
    setSnvDriverClassifications(getVariantDriverClassifications(snv));
    setCnvDriverClassifications(getVariantDriverClassifications(cnv));
    setFusionsDriverClassifications(
      getVariantDriverClassifications(rnaFusions)
    );
  }, [snv, cnv, rnaFusions]);

  const handleCompleteReclassificationButtonClick = () => {
    setCompleteReclassificationDialogVisible(true);
  };

  const handleApproveVariantsButtonClick = () => {
    setApproveVariantsDialogVisible(true);
  };

  const handleRejectVariantsButtonClick = () => {
    setRejectVariantsDialogVisible(true);
  };

  const handleRevertVariantsButtonClick = () => {
    setRevertReclassificationsDialogVisible(true);
  };

  const closeCompleteReclassificationDialog = () => {
    setCompleteReclassificationDialogVisible(false);
  };

  const closeApproveVariantsDialog = () => {
    setApproveVariantsDialogVisible(false);
  };

  const closeRejectVariantsDialog = () => {
    setRejectVariantsDialogVisible(false);
  };

  const closeRevertReclassificationsDialog = () => {
    setRevertReclassificationsDialogVisible(false);
  };

  const handleReclassifyVariants = useCallback(() => {
    closeCompleteReclassificationDialog();
    setReclassificationInProgress(false);
    reclassifyVariants({
      patientId,
      cnv,
      cnvDriverChanges,
      snv,
      snvDriverChanges,
      rnaFusions,
      rnaFusionsDriverChanges,
    });
  }, [
    cnv,
    cnvDriverChanges,
    patientId,
    reclassifyVariants,
    rnaFusions,
    rnaFusionsDriverChanges,
    snv,
    snvDriverChanges,
  ]);

  const handleVariantDecision = (
    endpointAction: typeof approveVariants | typeof rejectVariants,
    successAction?: () => void
  ) => {
    endpointAction({ patientId }).then(response => {
      if ("data" in response) {
        successAction?.();
      }
    });
  };

  const navigateToPatientsList = () => {
    // TODO: deep investigation is needed for routing in oncology app:
    //  * what routing is done in perl and what in react?
    //  * can we safely use react router to navigate, how is perl routing affected by react routing and vice versa?
    //  * can we improve some bits to have react routing instead of full page reloads for a better UX?
    if (history) {
      history.push(`/projects/${projectId}/patients`);
    } else {
      window.location.assign(`/projects/${projectId}/patients`);
    }
  };

  const handleApproveVariants = () => {
    closeApproveVariantsDialog();
    handleVariantDecision(approveVariants, navigateToPatientsList);
  };

  const handleRejectVariants = () => {
    closeRejectVariantsDialog();
    handleVariantDecision(rejectVariants, navigateToPatientsList);
  };

  const handleRevertReclassifications = () => {
    closeRevertReclassificationsDialog();

    handleVariantDecision(revertVariants, () =>
      setReclassificationInProgress(false)
    );
  };

  const variantsWorkflowAction = useMemo(
    () =>
      oncologyPatientWorkflowStatus?.availableActions?.find(
        ({ name }) =>
          // It should not have both so we're safe. There are also reject_analysis & first_review_reject_analysis actions,
          // but they match their counterpart action properties completely
          name === "approve_analysis" ||
          name === "first_review_approve_analysis"
      ),

    [oncologyPatientWorkflowStatus]
  );

  const actionsToShow = useMemo(() => {
    if (!variantsWorkflowAction) {
      return [];
    }

    const { userCanPerform, name } = variantsWorkflowAction;

    const isFirstReview = name === "first_review_approve_analysis";
    const reclassificationsPersistedInCurrentStatus =
      reclassificationFileSubtype &&
      [
        "reclassification_in_progress",
        "reclassification_first_review_in_progress",
      ].includes(reclassificationFileSubtype);

    // TODO: might be nice in the future to somehow indicate to user why actions are disabled when
    //  same user that performed first review sees them
    return [
      reclassificationsPersistedInCurrentStatus && {
        label: "Revert",
        icon: "undo",
        context: "default",
        isDisabled: !userCanPerform,
        "data-testid": "revert-reclassification-btn",
        tooltip: {
          content: "Revert reclassification changes made in current status",
        },
        onClick: handleRevertVariantsButtonClick,
      },
      reclassificationInProgress && {
        label: "Cancel Reclassification",
        context: "default",
        isDisabled: !reclassificationInProgress || !userCanPerform,
        "data-testid": "cancel-reclassification-btn",
        tooltip: {
          content: "Undo current changes and cancel reclassification",
        },
        onClick: handleCancelReclassificationButtonClick,
      },
      !reclassificationInProgress && {
        label: "Start Reclassification",
        icon: "pencil",
        context: "primary",
        isDisabled: !userCanPerform,
        "data-testid": "start-reclassification-btn",
        onClick: handleStartReclassificationButtonClick,
      },
      reclassificationInProgress && {
        label: "Save Reclassification",
        icon: "save",
        context: "primary",
        isDisabled: !userCanPerform || !driverChangesPresent,
        "data-testid": "complete-reclassification-btn",
        onClick: handleCompleteReclassificationButtonClick,
      },
      {
        label: isFirstReview ? "Reject First Review" : "Reject Final Review",
        icon: "removeSign",
        context: "danger",
        isDisabled: reclassificationInProgress || !userCanPerform,
        "data-testid": "reject-variants-btn",
        onClick: handleRejectVariantsButtonClick,
      },
      {
        label: isFirstReview ? "Approve First Review" : "Approve Final Review",
        icon: "okSign",
        context: "success",
        isDisabled: reclassificationInProgress || !userCanPerform,
        "data-testid": "approve-variants-btn",
        onClick: handleApproveVariantsButtonClick,
      },
    ].filter(Boolean);
  }, [
    variantsWorkflowAction,
    reclassificationInProgress,
    handleCancelReclassificationButtonClick,
    driverChangesPresent,
    reclassificationFileSubtype,
  ]);

  return (
    <LoadingOverlay dataTestId="explore-tab-loading" loading={isLoading}>
      <div className="container-fluid">
        {/* TODO: use context instead of prop drilling */}
        <OncologyVariantTabs
          patientId={patientId}
          reclassificationInProgress={reclassificationInProgress}
          snvs={snv || []}
          cnvs={cnv || []}
          fusions={rnaFusions || []}
          snvDriverClassifications={snvDriverClassifications}
          cnvDriverClassifications={cnvDriverClassifications}
          fusionsDriverClassifications={fusionsDriverClassifications}
          setSnvDriverClassifications={setSnvDriverClassifications}
          setFusionsDriverClassifications={setFusionsDriverClassifications}
          setCnvDriverClassifications={setCnvDriverClassifications}
          setGenomeBrowserShown={setGenomeBrowserShown}
        />

        {!genomeBrowserShown && (
          <div className="row">
            <div className="col-md-5">
              <ReclassificationLegend />
            </div>
            <div className="col-md-7">
              <div className="visible-xs visible-sm mt-2" />

              {variantsWorkflowAction ? (
                <Actions actions={actionsToShow} />
              ) : (
                // Showing this only when user cannot perform any variants workflow actions here, meaning variants are finalised
                <Download
                  context="default"
                  className="pull-right"
                  dataTestId="download-btn"
                  url={`${API_ENTITIES_BASE_URL}/patients/${patientId}/oncology/variants/download/vcf`}
                  filename={`${patientId}_variants.vcf`}
                  caption="Download Variants"
                />
              )}
            </div>
          </div>
        )}
      </div>

      <ConfirmationModal
        show={completeReclassificationDialogVisible}
        bodyText="Have all variant types and associated driver classification statuses been reviewed?"
        close={closeCompleteReclassificationDialog}
        onYesButtonClick={handleReclassifyVariants}
      />
      <ConfirmationModal
        show={approveVariantsDialogVisible}
        bodyText="Have all variant types and associated driver classification statuses been reviewed?"
        close={closeApproveVariantsDialog}
        onYesButtonClick={handleApproveVariants}
      />
      <ConfirmationModal
        show={rejectVariantsDialogVisible}
        bodyText="This action cannot be undone. Are you sure you want to proceed?"
        close={closeRejectVariantsDialog}
        onYesButtonClick={handleRejectVariants}
      />
      <ConfirmationModal
        show={revertReclassificationsDialogVisible}
        bodyText="Revert reclassification changes made in current status?"
        close={closeRevertReclassificationsDialog}
        onYesButtonClick={handleRevertReclassifications}
      />
    </LoadingOverlay>
  );
};

export default memo(OncologyExploreContainer);
