import React, { memo } from "react";

import { Button, Loading } from "pattern-library";
import { Table } from "pattern-library/elements/table7";

import { labels } from "modules/utils/thirdparty";

import styles from "./AnnotationSourcesTable.module.scss";

import congenicaApi from "api/congenica-api";

const FETCH_FAILURE_MESSAGE = "Cannot load annotation sources details.";

const TEST_ID = "annotation-source-table";

const typeMap: Record<AnnotationSource["type"], string> = {
  data: "Data",
  software: "Software",
};

const columns = [
  {
    Header: "Source",
    accessor: ({ attribute, type }) => labels[type][attribute] || attribute,
  },
  { Header: "Type", accessor: ({ type }) => typeMap[type] || type },
  { Header: "Version", accessor: "value" },
];

type AnnotationSourcesTableProps = {
  patientId: number;
};

const { useGetPatientQuery } = congenicaApi;

const AnnotationSourcesTable: React.FC<AnnotationSourcesTableProps> = memo(
  ({ patientId }) => {
    const { data, isLoading, isFetching, error, refetch } = useGetPatientQuery({
      patientId,
    });

    const renderContent = () => {
      if (isLoading || isFetching) return <Loading />;

      if (error) {
        return (
          <div className={styles.failureMessage}>
            <div>{FETCH_FAILURE_MESSAGE}</div>
            <Button onClick={() => refetch()}>Retry</Button>
          </div>
        );
      }

      return (
        <Table
          className={styles.componentTable}
          columns={columns}
          data={data?.annotation_sources || []}
        />
      );
    };

    return (
      <div data-testid={TEST_ID}>
        <h4 className={styles.componentHeader}>Patient Annotation Sources</h4>
        {renderContent()}
      </div>
    );
  }
);

export default AnnotationSourcesTable;
