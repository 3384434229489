import { isNil } from "ramda";
import React, { useCallback, useEffect, useMemo } from "react";

import { Actions, Download, Loading } from "pattern-library";

import OncologyQcTabs from "modules/oncologyQC/OncologyQcTabs";
import { useOncologyQCData } from "modules/oncologyQC/useOncologyQCData";
import {
  ACTION_FAIL_QC,
  ACTION_PASS_QC,
  QC_CHECKED,
} from "modules/oncologyReport/constants";
import { formatDate, formatTime } from "modules/utils";
import { API_ENTITIES_BASE_URL } from "modules/utils/baseUrls";

import { OncologyAction } from "./types";

import congenicaApi from "api/congenica-api";

const { useSetOncologyPatientWorkflowStatusMutation } = congenicaApi;

interface Props {
  patientId: number;
  qcJsonFileName?: string;
}

const TOOLTIP_CONFIG_QC_ACTION_APPLIES_TO_BOTH_DNA_AND_RNA = {
  placement: "top",
  content: "This applies for both DNA & RNA",
  trigger: "mouseenter",
};

const OncologyQC = ({ patientId }: Props) => {
  const {
    dnaQc,
    rnaQc,
    oncologyPatientWorkflowStatus,
    significantStatusChanges,
    qcJsonFileNamePrefix,
    loading: qcDataLoading,
  } = useOncologyQCData({
    patientId,
  });

  const [
    setOncologyPatientWorkflowStatus,
    { isLoading: setOncologyPatientWorkflowStatusLoading },
  ] = useSetOncologyPatientWorkflowStatusMutation();

  const significantStatusInfo = useMemo(() => {
    if (!significantStatusChanges[QC_CHECKED]) {
      return null;
    }

    const { action, email, fullname, updated } =
      significantStatusChanges[QC_CHECKED];
    const time: number = updated * 1000;

    return (
      <span
        data-testid="significant-status"
        className="qc__oncology__actions__significant-status"
      >
        QC {action === ACTION_PASS_QC ? "passed" : "failed"} by{" "}
        <a href={`mailto:${email}`}> {fullname}</a> at {formatTime(time)} on{" "}
        {formatDate(time)}
      </span>
    );
  }, [significantStatusChanges]);

  const onChangeStatus = useCallback(
    (actionName: string) => {
      const readyForReview =
        oncologyPatientWorkflowStatus?.currentStatus === "Ready for review";
      setOncologyPatientWorkflowStatus({
        patientId,
        actionName,
        // skipping invalidation of tags since we'll reload the window anyway
        skipInvalidateTags: readyForReview,
      }).then(response => {
        if ("data" in response && readyForReview) {
          window.location.reload();
        }
      });
    },
    [patientId, setOncologyPatientWorkflowStatus, oncologyPatientWorkflowStatus]
  );

  // This is needed to refresh perl rendered patient status at top right
  useEffect(() => {
    if (!isNil(window.Sapientia))
      window.Sapientia.getContent("#patient-title-status");
  }, [oncologyPatientWorkflowStatus?.currentStatus]);

  /**
   * GET /webapi/entities/patients/:patient_id/oncology/status will return a different list of available_actions depending on the patient's status, for oncology projects only:
   */
  const ACTIONS = useMemo<Record<string, OncologyAction>>(
    () => ({
      [ACTION_FAIL_QC]: {
        label: "QC Fail",
        icon: "removeSign",
        disabled: setOncologyPatientWorkflowStatusLoading,
        onClick: () => {
          onChangeStatus(ACTION_FAIL_QC);
        },
        context: "danger",
      },
      [ACTION_PASS_QC]: {
        label: "QC Pass",
        icon: "okSign",
        disabled: setOncologyPatientWorkflowStatusLoading,
        onClick: () => {
          onChangeStatus(ACTION_PASS_QC);
        },
        context: "success",
      },
    }),
    [setOncologyPatientWorkflowStatusLoading, onChangeStatus]
  );

  if (qcDataLoading) {
    return <Loading />;
  }

  return (
    <>
      <OncologyQcTabs dnaQc={dnaQc} rnaQc={rnaQc} />
      {(dnaQc || rnaQc) && (
        <div className="row mt-2">
          <div className="col-md-6" />
          <div className="col-md-6 qc__oncology__actions">
            <Download
              className="qc__oncology__actions__qc_download"
              url={`${API_ENTITIES_BASE_URL}/patients/${patientId}/oncology/qc_metrics/download`}
              filename={`${qcJsonFileNamePrefix}_QC_metrics.json`}
              caption="QC Metrics"
              dataTestId="qc-metric-download"
              context="default"
            />
            {/* TODO: unify component to show actions in QC, Explore & Report tabs */}
            <Actions
              actions={(
                oncologyPatientWorkflowStatus?.availableActions || []
              ).reduce((acc, { name, userCanPerform }) => {
                if (ACTIONS.hasOwnProperty(name)) {
                  acc.push({
                    ...ACTIONS[name],
                    disabled: ACTIONS[name].disabled || !userCanPerform,
                    tooltip:
                      dnaQc &&
                      rnaQc &&
                      TOOLTIP_CONFIG_QC_ACTION_APPLIES_TO_BOTH_DNA_AND_RNA,
                  });
                }
                return acc;
              }, [] as OncologyAction[])}
            />
            {significantStatusInfo}
          </div>
        </div>
      )}
    </>
  );
};

export default OncologyQC;
