import PropTypes from "prop-types";
import React, { memo } from "react";

import { Input } from "../form";

const FilterField = memo(({ value, onChange, placeholder = "Filter..." }) => {
  const handleChange = e => {
    const inputValue = e.target.value;
    onChange(inputValue);
  };

  return (
    <div className="dataTables_filter">
      <Input placeholder={placeholder} value={value} onChange={handleChange} />
    </div>
  );
});

FilterField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default FilterField;
