import { decamelizeKeys } from "humps";

import {
  CvlListResponse,
  ProjectCuratedVariantList,
} from "modules/curatedVariantsLists/types";
import { flattenCVLs } from "modules/curatedVariantsLists/utils";
import { getVepConsequenceLabel } from "modules/utils/common";
import { CheckboxOption } from "modules/utils/prop-types/CheckboxOptionProp";

import {
  flattenApiEntity,
  flattenApiList,
  flattenApiResponse,
  getApiListData,
  getFilterParams,
  getJsonApiAttrs,
} from "./utils/common";
import { prepareRoles } from "./utils/users";
import { prepareOncologyVariantsPatch } from "./utils/variants";

import baseApi from "./index";

import { CongenicaApiModel } from "api/congenica-api-schema/congenica-api-model";
import { OncologyCNV } from "types/CNV";
import { OncologyPatientWorkflowStatus } from "types/oncologyPatientWorkflowStatus";
import { OncologyDnaQcData, OncologyRnaQcData } from "types/oncologyQc";

const baseUrl = "/webapi";

const congenicaApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * System config
     */
    getSystemConfig: builder.query<SystemConfig, void>({
      query: () => `${baseUrl}/utils/config`,
      extraOptions: { camelize: true },
    }),

    /**
     * Patient endpoints
     */
    getPatient: builder.query<any, PatientDependant>({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}`,
      }),
      transformResponse: getJsonApiAttrs,
    }),
    getPatientOfflineStatus: builder.query<
      PatientOfflineStatus,
      PatientDependant
    >({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patient/${patientId}/offline_status`,
      }),
      transformResponse: getJsonApiAttrs,
      extraOptions: {
        camelize: true,
      },
    }),
    setPatientFamilyOfflineStatus: builder.mutation<
      PatientFamilyOfflineStatus,
      PatientDependant & PatientOfflineStatusRequestAttributesPayload
    >({
      query: ({ patientId, ...body }) => ({
        url: `${baseUrl}/entities/patient/${patientId}/family/offline_status`,
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
        body: {
          data: {
            id: patientId,
            type: "patient_family_offline_status",
            attributes: decamelizeKeys(body),
          },
        },
      }),
      transformResponse: getJsonApiAttrs,
      extraOptions: {
        camelize: true,
      },
      invalidatesTags: ["ProjectPatientsOfflineStatuses"],
    }),

    /**
     * VEP consequences
     */
    getVEPConsequences: builder.query<CheckboxOption[], void>({
      query: () => ({
        url: `${baseUrl}/entities/vep_consequences`,
      }),
      transformResponse: (resp: APIEntityArray) =>
        resp.data.map(({ id }) => ({
          key: id,
          label: getVepConsequenceLabel(id),
        })),
    }),

    /**
     * Third Party Data & Software endpoints
     */
    getThirdPartyDataVersions: builder.query<
      Version[],
      ThirdPartyFilterOptions
    >({
      query: filterOptions => ({
        url: `${baseUrl}/entities/thirdparty_data_versions`,
        params: getFilterParams(filterOptions),
      }),
      transformResponse: flattenApiList,
    }),
    getThirdPartySoftwareVersions: builder.query<
      Version[],
      ThirdPartyFilterOptions
    >({
      query: filterOptions => ({
        url: `${baseUrl}/entities/thirdparty_software_versions`,
        params: getFilterParams(filterOptions),
      }),
      transformResponse: flattenApiList,
    }),
    getProjectSettings: builder.query<ProjectSettings, ProjectDependant>({
      query: ({ projectId }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/settings/defaults`,
      }),
      transformResponse: flattenApiResponse,
      extraOptions: {
        camelize: true,
      },
    }),
    getCurrentUser: builder.query<CurrentUser, void>({
      query: () => ({
        url: `${baseUrl}/entities/current_user`,
      }),
      transformResponse: (response: CurrentUserResponse): CurrentUser => {
        const { is_admin, full_name, email, id } = flattenApiResponse(response);
        return {
          id,
          admin: is_admin,
          email,
          name: full_name,
        };
      },
    }),
    getAuthProvidersByUser: builder.query<AuthProvider[], { userId: number }>({
      query: ({ userId }) => ({
        url: `${baseUrl}/entities/authentication_providers/users/${userId}`,
      }),
      transformResponse: flattenApiList,
      extraOptions: {
        camelize: true,
      },
    }),
    getAuthProviders: builder.query<AuthProvider[], void>({
      query: () => ({
        url: `${baseUrl}/entities/authentication_providers`,
      }),
      transformResponse: flattenApiList,
      extraOptions: {
        camelize: true,
      },
    }),
    getRoles: builder.query<Role[], void>({
      query: () => ({
        url: `${baseUrl}/entities/projects/roles`,
      }),
      transformResponse: prepareRoles,
      extraOptions: {
        camelize: true,
      },
    }),
    getLatestReportProposal: builder.query<ReportProposal, PatientDependant>({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}/report_proposal/latest`,
        validateStatus: response =>
          response.status === 200 || response.status === 404,
      }),
      transformResponse: getJsonApiAttrs,
      providesTags: _ => ["ReportProposal"],
      extraOptions: {
        camelize: true,
      },
    }),
    getPatientReports: builder.query<PatientReport[], PatientDependant>({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}/reports`,
      }),
      transformResponse: (response: { data }) => response.data,
      providesTags: _ => ["PatientStatus"],
      extraOptions: {
        camelize: true,
      },
    }),
    setReportProposal: builder.mutation<
      ReportProposal,
      Partial<ReportProposal> & Required<PatientDependant>
    >({
      query({ patientId, ...body }) {
        return {
          url: `${baseUrl}/entities/patients/${patientId}/report_proposal`,
          method: "POST",
          body: JSON.stringify({
            data: {
              type: "report_proposal",
              attributes: decamelizeKeys(body),
            },
          }),
          headers: {
            "Content-Type": "application/vnd.api+json",
          },
        };
      },
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["ReportProposal"],
    }),
    getOncologyDnaQcMetrics: builder.query<OncologyDnaQcData, PatientDependant>(
      {
        query: ({ patientId }) => ({
          url: `${baseUrl}/entities/patients/${patientId}/oncology/qc_metrics/dna`,
          validateStatus: response =>
            response.status === 200 || response.status === 404,
        }),
        transformResponse: getJsonApiAttrs,
      }
    ),
    getOncologyRnaQcMetrics: builder.query<OncologyRnaQcData, PatientDependant>(
      {
        query: ({ patientId }) => ({
          url: `${baseUrl}/entities/patients/${patientId}/oncology/qc_metrics/rna`,
          validateStatus: response =>
            response.status === 200 || response.status === 404,
        }),
        transformResponse: getJsonApiAttrs,
      }
    ),

    getProjectFeaturesSettings: builder.query<
      ProjectFeaturesSettings,
      ProjectDependant
    >({
      query: ({ projectId }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/features/settings`,
      }),
      transformResponse: getJsonApiAttrs,
      extraOptions: {
        camelize: true,
      },
      providesTags: ["ProjectFeaturesSettings"],
    }),

    saveProjectFeaturesSettings: builder.mutation<
      ProjectFeaturesSettings,
      ProjectDependant & ProjectFeaturesSettingsPayload
    >({
      query: ({ projectId, ...payload }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/features/settings`,
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
      }),
      extraOptions: {
        camelize: true,
      },
      transformResponse: getJsonApiAttrs,
      invalidatesTags: ["ProjectFeaturesSettings"],
    }),

    getReportTemplates: builder.query<
      Array<ReportServiceTemplate>,
      ProjectDependant
    >({
      query: ({ projectId }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/report_service_templates`,
      }),
      transformResponse: getApiListData,
    }),

    getProjectCuratedLists: builder.query<
      Array<ProjectCuratedVariantList>,
      ProjectDependant & { filterOptions?: CVLsFilterOptions }
    >({
      query: ({ projectId, filterOptions = {} }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/curated_variant_lists`,
        params: getFilterParams(filterOptions),
      }),
      extraOptions: {
        camelize: true,
      },
      transformResponse: (response: CvlListResponse) =>
        flattenCVLs(getApiListData(response)),
    }),

    getProjectPatientsOfflineStatuses: builder.query<
      ProjectPatientOfflineStatus[],
      ProjectDependant
    >({
      query: ({ projectId }) => ({
        url: `${baseUrl}/entities/projects/${projectId}/offline_statuses`,
      }),
      extraOptions: {
        camelize: true,
      },
      transformResponse: flattenApiList,
      providesTags: ["ProjectPatientsOfflineStatuses"],
    }),

    getOncologyVariants: builder.query<
      {
        source: PatientDataFile;
        snv: OncologySNV[];
        cnv: OncologyCNV[];
        rnaFusions: OncologyFusion[];
      },
      PatientDependant
    >({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}/oncology/variants`,
      }),
      transformResponse: (resp: any) => ({
        source: flattenApiEntity(resp.data.attributes.source.data),
        snv: (resp.data.attributes.variants?.snv ?? []).map(e =>
          flattenApiEntity(e.data)
        ),
        cnv: (resp.data.attributes.variants?.dna_cnvs ?? []).map(e =>
          flattenApiEntity(e.data)
        ),
        rnaFusions: (resp.data.attributes.variants?.rna_fusions ?? []).map(e =>
          flattenApiEntity(e.data)
        ),
      }),
      providesTags: _ => ["OncologyVariants"],
    }),

    reclassifyOncologyVariants: builder.mutation<
      any,
      {
        patientId: number;
        cnv?: OncologyCNV[];
        cnvDriverChanges: Record<string, boolean>;
        snv?: OncologySNV[];
        snvDriverChanges: Record<string, boolean>;
        rnaFusions?: OncologyFusion[];
        rnaFusionsDriverChanges: Record<string, boolean>;
      }
    >({
      query({
        patientId,
        cnv = [],
        cnvDriverChanges,
        snv = [],
        snvDriverChanges,
        rnaFusions = [],
        rnaFusionsDriverChanges,
      }) {
        return {
          url: `${baseUrl}/entities/patients/${patientId}/oncology/variants`,
          method: "PATCH",
          body: JSON.stringify({
            data: {
              id: patientId,
              type: "oncology_variants",
              attributes: {
                variants: {
                  snv: prepareOncologyVariantsPatch(
                    "oncology_snv_variants",
                    snv,
                    snvDriverChanges
                  ),
                  dna_cnvs: prepareOncologyVariantsPatch(
                    "oncology_dna_copy_number_variant",
                    cnv,
                    cnvDriverChanges
                  ),
                  rna_fusions: prepareOncologyVariantsPatch(
                    "oncology_fusion_variants",
                    rnaFusions,
                    rnaFusionsDriverChanges
                  ),
                },
              },
            },
          }),
          headers: {
            "Content-Type": "application/vnd.api+json",
          },
        };
      },
      invalidatesTags: ["OncologyVariants"],
    }),

    revertOncologyVariants: builder.mutation<any, PatientDependant>({
      query({ patientId }) {
        return {
          url: `${baseUrl}/entities/patients/${patientId}/oncology/variants`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["OncologyVariants"],
    }),

    approveOncologyVariants: builder.mutation<any, PatientDependant>({
      query({ patientId }) {
        return {
          url: `${baseUrl}/entities/patients/${patientId}/oncology/variants/approval`,
          method: "POST",
          headers: {
            "Content-Type": "application/vnd.api+json",
          },
          body: JSON.stringify({
            data: {
              id: patientId,
              type: "oncology_variant_approval",
              attributes: {},
            },
          }),
        };
      },
    }),

    rejectOncologyVariants: builder.mutation<any, PatientDependant>({
      query({ patientId }) {
        return {
          url: `${baseUrl}/entities/patients/${patientId}/oncology/variants/rejection`,
          method: "POST",
          headers: {
            "Content-Type": "application/vnd.api+json",
          },
          body: JSON.stringify({
            data: {
              id: patientId,
              type: "oncology_variant_rejection",
              attributes: {},
            },
          }),
        };
      },
    }),

    getOncologyPatientWorkflowStatus: builder.query<
      OncologyPatientWorkflowStatus,
      PatientDependant
    >({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/patients/${patientId}/oncology/status`,
        method: "GET",
      }),
      transformResponse: getJsonApiAttrs,
      providesTags: _ => ["PatientStatus"],
      extraOptions: {
        camelize: true,
      },
    }),

    setOncologyPatientWorkflowStatus: builder.mutation<
      CongenicaApiModel["patient_workflow_status_detail_response"],
      PatientDependant & { actionName: string; skipInvalidateTags?: boolean }
    >({
      query: ({ patientId, actionName }) => ({
        url: `${baseUrl}/entities/patients/${patientId}/oncology/status`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify({
          data: {
            id: String(patientId),
            type: "patient_workflow_status",
            attributes: {
              perform_action: actionName,
            },
          },
        }),
      }),
      invalidatesTags: (_result, _error, arg) =>
        arg.skipInvalidateTags ? [] : ["PatientStatus"],
    }),

    getOncologyPatientData: builder.query<
      OncologyPatientData,
      PatientDependant
    >({
      query: ({ patientId }) => ({
        url: `${baseUrl}/entities/oncology/patients/${patientId}`,
      }),
      transformResponse: getJsonApiAttrs,
    }),
  }),
});

export default congenicaApi;
