import enGB from "date-fns/locale/en-GB";
import "font-awesome/css/font-awesome.min.css";
import React from "react";
import { registerLocale } from "react-datepicker";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "./scss/main.scss";
import store from "common/store";
import PatientLayout from "layout/PatientLayout";
import BrowserTitle from "layout/partials/BrowserTitle";
import AboutModalContent from "modules/about/components/AboutModalContent";
import { setDecisionAlreadySetAgainstAnotherTranscript } from "modules/acmg/actions";
import AcmgClassification from "modules/acmg/components/AcmgClassification";
import AcmgTab from "modules/acmg/components/AcmgTab";
import AnnotationSourcesTable from "modules/annotationSources/components/AnnotationSourcesTable";
import { BreakdownOfAriadneContainer } from "modules/ariadne/components/BreakdownOfAriadne";
import { AcmgAuditInfo, AuditInfo } from "modules/audit";
import CuratedVariantModal from "modules/curatedVariant/components/CuratedVariantModal";
import Exomiser from "modules/exomiser/Container";
import { GenoverseBrowser } from "modules/genoverse";
import { CongenicaGenoverseBrowser } from "modules/genoverseBrowser";
import IRContainer from "modules/interpretationRequests/IRContainer";
import {
  processLegacyDecisionValues,
  processLegacyStrDecisionValues,
} from "modules/legacy/actions";
import MessagesContainer from "modules/messages/Container";
import { message } from "modules/messages/actions";
import { OncologyExplore } from "modules/oncologyExplore";
import OncologyPatientOverview from "modules/oncologyPatientOverview/OncologyPatientOverview";
import OncologyQC from "modules/oncologyQC/OncologyQC";
import { OncologyReport } from "modules/oncologyReport";
import VariantCVLAudit from "modules/patient/components/audit/rare-disease/VariantCVLAudit";
import PatientOnliningButton from "modules/patientOnliningButton/PatientOnliningButton";
import PatientsContainer from "modules/patients/PatientsContainer";
import GenePanels from "modules/projectGenePanels/GenePanels";
import {
  AddProject,
  AdminConfiguration,
  Automation,
  FilterPresets,
  ProjectDefaults,
  ProjectDetails,
  VariantPanel,
} from "modules/projectSettings/components";
import PatientExportContainer from "modules/projectSettings/components/patientExport/PatientExportContainer";
import { UsersContainer } from "modules/projectUsers";
import { connector as ProjectsContainer } from "modules/projects/Container";
import { QCContainer } from "modules/qc";
import { RefNoCall } from "modules/refNoCall";
import SNVPresetsContainer from "modules/sequenceVariantsPresets/SNVPresetsContainer";
import { SequenceVariantSummary } from "modules/sequenceVariantsSummary";
import { STRsContainer } from "modules/str";
import {
  PatientOverlap,
  SVFilterSidebar,
  SVColumnsDropdown,
  setSVViewMode,
} from "modules/structuralVariants";
import HiGeneNameColumn from "modules/structuralVariants/components/HiGeneNameColumn";
import { StructuralVariantDetails } from "modules/structuralVariants/components/StructuralVariantDetails";
import {
  getLegacyFilters,
  getSVTableColumns,
  isSelectedPresetCustomized,
} from "modules/structuralVariants/selectors";
import { resetToDefaultPreset } from "modules/structuralVariantsPresets/actions";
import { getSelectedPresetId } from "modules/structuralVariantsPresets/selectors";
import { default as SystemConfig } from "modules/systemConfig/Container";
import VariantHistoryContainer from "modules/variantHistory/VariantHistoryContainer";
import { default as SequenceVariants } from "modules/variants/Container";
import { setActiveTranscript } from "modules/variants/actions";
import { CuratedVariantsColumnWrapper } from "modules/variants/components/CuratedVariantsColumnWrapper";

import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import { StickyToLeftFooter, StickyToLeftHeader } from "./layout/partials";

registerLocale("en-GB", enGB);

if (!window.reduxStore) {
  window.reduxStore = store;
}

// This exists only to facilitate communication between js code in perl templates
// and react code, it should only be used when there is no other option
window.reduxActions = {
  message,
  processLegacyDecisionValues,
  processLegacyStrDecisionValues,
  setDecisionAlreadySetAgainstAnotherTranscript,
  setActiveTranscript,
  resetToDefaultPreset,
  setSVViewMode,
};

window.selectors = {
  getLegacyFilters,
  getSVTableColumns,
  isSelectedPresetCustomized,
  getSelectedPresetId,
};

window.components = {
  SNVPresetsContainer,
  CuratedVariantModal,
  AddProject,
  AcmgClassification,
  AcmgTab,
  VariantPanel,
  ProjectDefaults,
  MessagesContainer,
  AdminConfiguration,
  ProjectDetails,
  CuratedVariantsColumnWrapper,
  SequenceVariants,
  IRContainer,
  Exomiser,
  Automation,
  RefNoCall,
  FilterPresets,
  PatientsContainer,
  AcmgAuditInfo,
  AuditInfo,
  UsersContainer,
  GenePanels,
  PatientExportContainer,
  STRsContainer,
  HiGeneNameColumn,
  SVColumnsDropdown,
  SVFilterSidebar,
  PatientOverlap,
  QCContainer,
  GenoverseBrowser,
  CongenicaGenoverseBrowser,
  ProjectsContainer,
  OncologyReport,
  AboutModalContent,
  VariantHistoryContainer,
  OncologyQC,
  BreakdownOfAriadneContainer,
  AnnotationSourcesTable,
  StructuralVariantDetails,
  SequenceVariantSummary,
  PatientLayout,
  Footer: StickyToLeftFooter,
  Header: StickyToLeftHeader,
  OncologyPatientOverview,
  BrowserTitle,
  OncologyExplore,
  VariantCVLAudit,
  PatientOnliningButton,
};

window.renderReactRoute = (
  Component,
  componentArgs,
  store,
  mountpoint,
  callback?: () => void
) => {
  ReactDOM.render(
    <Provider store={store}>
      <ErrorBoundary>
        <SystemConfig>
          <Component {...componentArgs} />
        </SystemConfig>
      </ErrorBoundary>
    </Provider>,
    document.querySelector(mountpoint),
    callback
  );
};

const prepareRender = async () => {
  if (process.env.REACT_APP_MOCK_SERVER === "1") {
    return import("./utils/mock-worker").then(workerModule => {
      workerModule.default.start();
    });
  }
  return Promise.resolve();
};

window.renderStandaloneApp = () => {
  prepareRender().then(() => {
    const rootElement = document.getElementById("root");

    ReactDOM.render(
      <Provider store={window.reduxStore}>
        <BrowserRouter>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>,
      rootElement
    );
  });
};

window.unmountReactNode = selector => {
  if (selector && document.querySelector(selector)) {
    ReactDOM.unmountComponentAtNode(document.querySelector(selector));
  }
};

// this has to be done manually and before the mounted component starts to respond to state changes
// otherwise there is no way to trigger non-React context to clear the virtual DOM
window.unmountACMGComponents = () => {
  window.unmountReactNode("#acmg-guidelines-grid");
  window.unmountReactNode("#acmg-pathogenicity");
  window.unmountReactNode("#exomiser");
};
