import { useMemo } from "react";

import congenicaApi from "api/congenica-api";
import { OncologyCNV } from "types/CNV";
import { OncologyPatientWorkflowStatus } from "types/oncologyPatientWorkflowStatus";

const {
  useGetOncologyVariantsQuery,
  useReclassifyOncologyVariantsMutation,
  useRevertOncologyVariantsMutation,
  useApproveOncologyVariantsMutation,
  useRejectOncologyVariantsMutation,
  useGetOncologyPatientWorkflowStatusQuery,
} = congenicaApi;

interface OncologyExploreData {
  reclassifyVariants: ReturnType<
    typeof useReclassifyOncologyVariantsMutation
  >[0];
  revertVariants: ReturnType<typeof useRevertOncologyVariantsMutation>[0];
  approveVariants: ReturnType<typeof useApproveOncologyVariantsMutation>[0];
  rejectVariants: ReturnType<typeof useRejectOncologyVariantsMutation>[0];
  snv: OncologySNV[] | undefined;
  cnv: OncologyCNV[] | undefined;
  rnaFusions: OncologyFusion[] | undefined;
  oncologyPatientWorkflowStatus: OncologyPatientWorkflowStatus | undefined;
  reclassificationFileSubtype: PatientDataFile["data_file_subtype"] | undefined;
  isLoading: boolean;
}

export const useOncologyExploreData = (
  patientId: number
): OncologyExploreData => {
  const [reclassifyVariants, { isLoading: isReclassifyVariantsLoading }] =
    useReclassifyOncologyVariantsMutation();

  const [revertVariants, { isLoading: isRevertVariantsLoading }] =
    useRevertOncologyVariantsMutation();

  const [approveVariants, { isLoading: isApproveVariantsLoading }] =
    useApproveOncologyVariantsMutation();

  const [rejectVariants, { isLoading: isRejectVariantsLoading }] =
    useRejectOncologyVariantsMutation();

  const {
    isOncologyVariantsLoading,
    snv,
    cnv,
    rnaFusions,
    reclassificationFileSubtype,
  } = useGetOncologyVariantsQuery(
    {
      patientId,
    },
    {
      selectFromResult: ({
        isLoading: isOncologyVariantsLoading,
        data: { snv, cnv, rnaFusions, source } = {},
      }) => ({
        isOncologyVariantsLoading,
        snv,
        cnv,
        rnaFusions,
        reclassificationFileSubtype: source?.data_file_subtype,
      }),
    }
  );

  const {
    data: oncologyPatientWorkflowStatus,
    isLoading: oncologyPatientWorkflowStatusLoading,
  } = useGetOncologyPatientWorkflowStatusQuery({
    patientId,
  });

  const isLoading = useMemo(
    () =>
      isOncologyVariantsLoading ||
      isReclassifyVariantsLoading ||
      isRevertVariantsLoading ||
      oncologyPatientWorkflowStatusLoading ||
      isApproveVariantsLoading ||
      isRejectVariantsLoading,
    [
      isOncologyVariantsLoading,
      isReclassifyVariantsLoading,
      isRevertVariantsLoading,
      isApproveVariantsLoading,
      isRejectVariantsLoading,
      oncologyPatientWorkflowStatusLoading,
    ]
  );

  return {
    reclassifyVariants,
    revertVariants,
    approveVariants,
    rejectVariants,
    oncologyPatientWorkflowStatus,
    snv,
    cnv,
    rnaFusions,
    reclassificationFileSubtype,
    isLoading,
  };
};
