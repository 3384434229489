import { decamelizeKeys } from "humps";

import { getFormData } from "../modules/utils/fetchData";

import {
  formatGenePanelPatients,
  formatGeneResponse,
} from "./utils/genePanels";
import { getUserRoles } from "./utils/users";

import baseApi from "./index";

const legacyApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getExistingUsers: builder.query<User[], ProjectDependant>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/admin/user/add/existing`,
      }),
      extraOptions: {
        legacy: true,
      },
      transformResponse: (response: Record<string, unknown>[]): User[] =>
        response.map(
          ({ email, fullname, user_id }: LegacyUser): User => ({
            email,
            fullName: fullname,
            userId: user_id,
          })
        ),
    }),
    getExistingUserDetails: builder.query<
      ProjectUser,
      ProjectDependant & { userId: number }
    >({
      query: ({ projectId, userId }) => ({
        url: `/project/${projectId}/admin/user/${userId}/add_permissions`,
      }),
      transformResponse: ({
        project_user: {
          visibility_level_id,
          fullname,
          user_id,
          access_level,
          authentication_provider_id,
          email,
        },
        project: { code },
        access_levels = [],
      }): ProjectUser => ({
        userId: user_id,
        fullName: fullname,
        visibilityLevelId: visibility_level_id
          ? Number(visibility_level_id)
          : visibility_level_id,
        projectCode: code,
        roles: getUserRoles(access_levels),
        accessLevel: access_level,
        authenticationProviderId: authentication_provider_id,
        email,
      }),
      extraOptions: {
        legacy: true,
      },
    }),
    addProjectUser: builder.mutation<
      void,
      ProjectUserRequest & Required<ProjectDependant>
    >({
      query({ projectId, ...body }) {
        return {
          url: `/project/${projectId}/admin/user/add`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
      extraOptions: {
        legacy: true,
      },
    }),
    editProjectUser: builder.mutation<
      void,
      ProjectUserRequest & Required<ProjectDependant> & { userId: number }
    >({
      query({ projectId, userId, ...body }) {
        return {
          url: `/project/${projectId}/admin/user/${userId}/edit`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
      extraOptions: {
        legacy: true,
      },
    }),
    addPermissions: builder.mutation<
      void,
      ProjectUserPermissionsRequest & ProjectDependant & { userId: number }
    >({
      query({ projectId, userId, ...body }) {
        return {
          url: `/project/${projectId}/admin/user/${userId}/add_permissions`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
      extraOptions: {
        legacy: true,
      },
    }),
    removeProjectUser: builder.mutation<
      any,
      ProjectDependant & { userId: number }
    >({
      query({ projectId, userId }) {
        return {
          url: `/project/${projectId}/admin/user/${userId}/delete`,
          method: "POST",
        };
      },
    }),
    getFastQC: builder.query<any, PatientDependant>({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/qc/fastqc/json`,
      }),
    }),
    getVcfQC: builder.query<any, PatientDependant>({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/qc/vcf/json`,
      }),
    }),

    getExistingGenePanelsForProject: builder.query<
      { genePanels: CopyProjectGenePanel[]; writePermissions: boolean },
      ProjectDependant
    >({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/gene-panel/add/copy`,
      }),
      transformResponse: (resp: any) => ({
        ...resp,
        writePermissions: Boolean(resp.writePermissions),
      }),
      extraOptions: {
        camelize: true,
        legacy: true,
      },
    }),

    addExistingGenePanelToProject: builder.mutation<
      any,
      ProjectDependant & { genePanelId: number }
    >({
      query: ({ genePanelId, projectId }) => ({
        url: `/project/${projectId}/gene-panel/add/${genePanelId}`,
        method: "POST",
        // Text handler is needed, as the legacy endpoint returns HTML when posted to,
        // regardless of the legacy Accept header being passed
        responseHandler: "text",
      }),
      invalidatesTags: ["ProjectGenePanels"],
    }),

    getProjectGenePanels: builder.query<
      {
        genePanels: ProjectGenePanel[];
        inheritedGenePanels: ProjectGenePanel[];
      },
      ProjectDependant & { archived?: boolean }
    >({
      query: ({ projectId, archived }) => ({
        url: `/project/${projectId}/gene-panels`,
        params: {
          show_archived: archived ? "1" : "0",
        },
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
      providesTags: ["ProjectGenePanels"],
    }),

    addNewGenePanel: builder.mutation<
      any,
      ProjectDependant & {
        genePanel: ProjectGenePanelBase;
      }
    >({
      query: ({ projectId, genePanel }) => {
        const body = JSON.stringify(decamelizeKeys(genePanel));
        return {
          url: `/project/${projectId}/gene-panel/add/new`,
          method: "POST",
          body,
        };
      },
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["ProjectGenePanels"],
    }),

    uploadGenePanelFile: builder.mutation<
      { tempGenePanelId: number },
      ProjectDependant & {
        genePanelFile: ProjectGenePanelFilePayload;
      }
    >({
      query: ({ projectId, genePanelFile }) => ({
        url: `/project/${projectId}/gene-panel/add/upload/react`,
        method: "POST",
        body: getFormData(genePanelFile),
        headers: {
          credentials: "include",
        },
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
    }),

    getTempGenePanel: builder.query<
      TempGenePanel,
      ProjectDependant & TempGenePanelDependant
    >({
      query: ({ projectId, tempGenePanelId }) => ({
        url: `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/info`,
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
      transformResponse: (response: any): TempGenePanel => response.genePanel,
      providesTags: ["TempGenePanel"],
    }),

    getTempGenePanelFoundGenes: builder.query<
      Array<GenePanelGene>,
      ProjectDependant & TempGenePanelDependant
    >({
      query: ({ projectId, tempGenePanelId }) => ({
        url: `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/genes`,
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
      transformResponse: (response: any): Array<GenePanelGene> =>
        response.genes,
      providesTags: ["TempGenePanelGenes"],
    }),

    getTempGenePanelNotFoundGenes: builder.query<
      Array<GenePanelGene>,
      ProjectDependant & TempGenePanelDependant
    >({
      query: ({ projectId, tempGenePanelId }) => ({
        url: `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/genes/not/found`,
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
      transformResponse: (response: any): Array<GenePanelGene> =>
        response.genes,
    }),

    removeGeneFromTempGenePanel: builder.mutation<
      any,
      ProjectDependant & TempGenePanelDependant & { geneName: string }
    >({
      query: ({ projectId, tempGenePanelId, geneName }) => ({
        url: `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/gene/${geneName}/delete`,
        method: "POST",
        headers: {
          credentials: "include",
        },
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["TempGenePanelGenes"],
    }),

    updateTempGenePanelInfo: builder.mutation<
      any,
      ProjectDependant & TempGenePanelDependant & { data: ProjectGenePanelBase }
    >({
      query: ({ projectId, tempGenePanelId, data }) => ({
        url: `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/info`,
        method: "POST",
        body: getFormData(data),
        headers: {
          credentials: "include",
        },
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["TempGenePanel"],
    }),

    saveTempGenePanel: builder.mutation<
      any,
      ProjectDependant & TempGenePanelDependant
    >({
      query: ({ projectId, tempGenePanelId }) => ({
        url: `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/save`,
        method: "POST",
        headers: {
          credentials: "include",
        },
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["ProjectGenePanels"],
    }),

    getTempGenePanelGene: builder.query<
      GenePanelEditGene,
      ProjectDependant & TempGenePanelDependant & { geneName: string }
    >({
      query: ({ projectId, tempGenePanelId, geneName }) => ({
        url: `/project/${projectId}/gene-panel/add/upload/${tempGenePanelId}/gene/${geneName}/edit`,
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
    }),

    updateTempGenePanelGene: builder.mutation<void, EditGeneRequest>({
      query: ({ projectId, genePanelId, geneName, data }) => ({
        url: `/project/${projectId}/gene-panel/add/upload/${genePanelId}/gene/${geneName}/edit`,
        method: "POST",
        body: JSON.stringify(decamelizeKeys(data)),
        headers: {
          credentials: "include",
        },
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["TempGenePanelGenes"],
    }),

    getGenePanelInfo: builder.query<
      GenePanelInfo,
      ProjectDependant & GenePanelDependant
    >({
      query: ({ projectId, genePanelId }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}`,
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
      transformResponse: (response: any): GenePanelInfo => response.genePanel,
      providesTags: ["GenePanelInfo"],
    }),

    updateGenePanel: builder.mutation<void, GenePanelUpdateRequest>({
      query: ({ projectId, genePanelId, ...genePanelData }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/info`,
        method: "POST",
        body: JSON.stringify(decamelizeKeys(genePanelData)),
        headers: {
          credentials: "include",
        },
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["GenePanelInfo", "ProjectGenePanels"],
    }),

    getGenePanelPatients: builder.query<
      GenePanelPatients,
      ProjectDependant & GenePanelDependant
    >({
      query: ({ projectId, genePanelId }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/patients/list`,
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
      transformResponse: (response: GenePanelPatientsResponse) =>
        formatGenePanelPatients(response),
      providesTags: ["GenePanelPatients"],
    }),

    addPatientToGenePanel: builder.mutation<
      void,
      ProjectDependant & GenePanelDependant & PatientDependant
    >({
      query: ({ projectId, genePanelId, patientId }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/patient/${patientId}/add`,
        method: "POST",
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["GenePanelInfo", "GenePanelPatients"],
    }),

    removePatientFromGenePanel: builder.mutation<
      void,
      ProjectDependant & GenePanelDependant & PatientDependant
    >({
      query: ({ projectId, genePanelId, patientId }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/patient/${patientId}/delete`,
        method: "POST",
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["GenePanelInfo", "GenePanelPatients"],
    }),

    addAllPatientsToGenePanel: builder.mutation<
      void,
      ProjectDependant & GenePanelDependant
    >({
      query: ({ projectId, genePanelId }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/patients/add/all`,
        method: "POST",
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["GenePanelInfo", "GenePanelPatients"],
    }),

    removeAllPatientsFromGenePanel: builder.mutation<
      void,
      ProjectDependant & GenePanelDependant
    >({
      query: ({ projectId, genePanelId }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/patients/delete/all`,
        method: "POST",
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["GenePanelInfo", "GenePanelPatients"],
    }),

    getGenePanelGenes: builder.query<
      Array<GenePanelGene>,
      ProjectDependant & GenePanelDependant
    >({
      query: ({ projectId, genePanelId }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/genes/list`,
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
      transformResponse: (response: any): Array<GenePanelGene> =>
        response.genes,
      providesTags: ["GenePanelGenes"],
    }),
    removeGeneFromGenePanel: builder.mutation<
      void,
      ProjectDependant & GenePanelDependant & GeneDependant
    >({
      query: ({ projectId, genePanelId, gene }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/gene/${gene}/delete`,
        method: "POST",
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: [
        "GenePanelGenes",
        "GenePanelInfo",
        "GenePanelGenesSearch",
        "ProjectGenePanels",
      ],
    }),

    searchGenePanelGenes: builder.query<
      GenePanelGenesSearchResponse,
      ProjectDependant & GenePanelDependant & { query: string }
    >({
      query: ({ projectId, genePanelId, query }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/gene/search`,
        params: {
          query,
        },
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
      providesTags: ["GenePanelGenesSearch"],
    }),

    addGeneToGenePanel: builder.mutation<void, GeneToGenePanelRequest>({
      query: ({ projectId, genePanelId, geneName, fields }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/gene/${geneName}/add`,
        method: "POST",
        body: getFormData({ ...(fields || {}), no_redirect: "1" }),
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: [
        "GenePanelInfo",
        "GenePanelGenes",
        "GenePanelGenesSearch",
        "ProjectGenePanels",
      ],
    }),

    getGenePanelEditGeneData: builder.query<
      GenePanelEditGene,
      ProjectDependant & GenePanelDependant & GeneNameDependant
    >({
      query: ({ projectId, genePanelId, geneName }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/gene/${geneName}/edit`,
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
    }),

    updateGenePanelGene: builder.mutation<void, EditGeneRequest>({
      query: ({ projectId, genePanelId, geneName, data }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/gene/${geneName}/edit`,
        method: "POST",
        body: JSON.stringify(decamelizeKeys(data)),
        headers: {
          credentials: "include",
        },
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["GenePanelGenes", "GenePanelGenesSearch"],
    }),

    getGeneInfo: builder.query<
      GeneInfo,
      GeneNameDependant & EnsemblVersionDependant
    >({
      query: ({ geneName, ensemblVersion }) => ({
        url: `/gene/${geneName}`,
        params: {
          ensembl_version: ensemblVersion,
        },
      }),
      extraOptions: {
        legacy: true,
        camelize: true,
      },
      transformResponse: formatGeneResponse,
    }),

    archiveGenePanel: builder.mutation<
      void,
      ProjectDependant & GenePanelDependant
    >({
      query: ({ projectId, genePanelId }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/archive`,
        method: "POST",
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["ProjectGenePanels"],
    }),

    removeGenePanel: builder.mutation<
      void,
      ProjectDependant & GenePanelDependant
    >({
      query: ({ projectId, genePanelId }) => ({
        url: `/project/${projectId}/gene-panel/${genePanelId}/delete`,
        method: "POST",
      }),
      extraOptions: {
        legacy: true,
      },
      invalidatesTags: ["ProjectGenePanels"],
    }),
  }),
});

export default legacyApi;
